<template>
  <div>
      <div class="content">
          <h1>Fragen & Antworten</h1>
      <div class="faq" v-bind:key="faq + index" v-for="(faq, index) in faqs">
        <div class="question-header" v-on:click="toggleQ(index)">
            <h2>{{ faq.question }}</h2>
          <!-- <span
            style="font-weight: 500; font-family: Dosis; font-size: 1.2rem"
            >{{ faq.question }}</span
          >
        </div> -->
        <div class="answer" :id="'answer'+index" name="faq">
          <p v-bind:key="para" v-for="para in faq.answer">
            {{ para }}
          </p>
        </div>
      </div>
      </div>
  </div>
  </div>	
</template>

<script>
export default {
  name: "Faq",
  methods: {
    toggleQ: function (index) {
      const allEls = document.getElementsByName("faq");
      allEls.forEach((el) => {
        el.removeAttribute("style");
      });
      const answerEl = document.getElementById("answer" + index);
      const elHeight = answerEl.scrollHeight;
      answerEl.style.maxHeight = elHeight + "px";
      // answerEl.style.padding = '1.25rem'
    },
  },
  mounted() {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  },
  data() {
    return {
      faqs: [
        {
          question: "Wo kann leamos installiert werden?",
          answer: [
            "leamos ist keine native APP, sondern wurde mit im Progressive Web-App Design programmiert. Diese moderne Technologie ermöglicht es, dass leamos auf unterschiedlichen Bildschirmgeräten (Computer, Laptop, Tablett oder Handy) alternierend aufgerufen werden kann. Es muss nicht installiert werden.",
            "Kinder können dadurch wahlweise in der Schule und zu Hause trainieren. Da Handys sehr klein sind, ist die Verwendung auf Tablett oder Notebook empfohlen.",
          ],
        },
        {
          question: "Für wen ist leamos geeignet?",
          answer: [
            "leamos wird von einem breiten Spektrum an Klienten verwendet. Vom Leseanfänger bis zu jenen, die an Lesegenauigkeit und Lesegeschwindigkeit arbeiten wollen.",
            "leamos kommt als Training in Frage, sobald Klienten die Anfangsbuchstaben (Dehnmitlaute und Vokale) gelernt, aber noch nicht automatisiert haben. Wichtig ist, dass das Konzept des Zusammenschleifens von Buchstaben grundsätzlich beherrscht wird. ",
            "Jene, die die Lesegenauigkeit verbessern wollen, finden eine intensive Trainingsmöglichkeit in  leamos.",
            "leamos Trainingseinheiten verbessern in der Folge auch die Lesegeschwindigkeit. ",
            "Mit einer Vielzahl von Trainingslisten wird an der visuellen Wortbildspeicherung gearbeitet. Wörter müssen dann auch getippt werden, um das aktive Anwenden der abgespeicherten Worte zu trainieren. Nicht zuletzt trainiert leamos auch das visuelle und auditive Kurzzeitgedächtnis.",
          ],
        },
        {
          question: "Was trainiert leamos besonders?",
          answer: [
              "leamos trainiert eine Reihe von Lesefertigkeiten.",
              "• Automatisierung des Zusammenlautierens von Buchstaben-kombinationen  und Silben",
               "• Automatisierung im Erkennen von Buchstaben, Buchstabenkombinationen, Silben und Wörtern",
               "• Training von Reihenfolgen mit Symbolen",
               "• Erkennen von Wortbildern auf einen Blick",
               "• Training des synthetischen Lesevorgangs mit Pseudoworten, die der deutschen Wortstruktur entsprechen.",
               "• Lesegenauigkeit bei Pseudoworten und Wörtern",
               "• Lesegeschwindigkeit",
               "• Richten der Aufmerksamkeit auf einzelne Buchstaben bzw. Buchstabenkombinationen",
               "• Training des auditiven und visuellen Kurzzeitgedächtnisses ",
               "• Stabilisierung der inneren Wortbilder",
          ],
        },
        {
          question: "Kann leamos alle Leseprobleme lösen?",
          answer: [
              "leamos ist zum Lesen üben sehr gut geeignet. leamos hat den Schwerpunkt auf Lesegenauigkeit, Lesegeschwindigkeit und Visualisierung.",
              "„Lesen kann man nur durch Lesen üben.  Nur üben macht nur dann Sinn, wenn die körperliche Basis altersentsprechend gut entwickelt ist.“ Daher sollte bei Bedarf neben leamos auch die körperliche Basis gefördert werden. ",
              "Die Phonologische Bewusstheit wird mit leamos nicht trainiert. ",
              
          ],
        },
        {
          question:
            "Ist leamos für Schulen oder Therapeuten oder Privatpersonen?",
          answer: [
            "leamos ist für Schulen im regulären Unterricht, für Lesefördergruppen, für Lern- oder Legasthenietherapeuten und für Kinder geeignet.",
          ],
        },
        {
          question: "Wie trainieren Klienten mit unterschiedlichem Leseniveau?",
          answer: [
            "leamos hat drei verschiedene Einstiege, die jeweils für unterschiedliche Leseniveaus geeignet sind.",
            "Der Student / die Studentin  ist ein Leser, bei dem noch nicht alle Buchstaben gefestigt sind. Das Zusammenlautieren ist noch nicht automatisiert.",
            "Der Praktikant  / die Praktikantin hat alle Buchstaben automatisiert und das Zusammenlautieren gelingt schon sicher. Der Praktikant arbeitet an seiner Lesegenauigkeit. Das Erkennen von Buchstabenkombinationen und Wörtern auf einen Blick ist Teil des Trainings.",
            "Der Trainee ist dabei sich zu einem geübten, schnellen Leser zu entwickeln. Schwierige Konsonantencluster bzw. Buchstabenkombinationen oder längere Worte benötigen aber noch Training.  Der Trainee möchte seine Lesegeschwindigkeit steigern. Ziel sind 5 Buchstaben-Pseudoworte in 250ms.  Ein weiteres Augenmerk wird auf das Abspeichern und Abrufen von Wortbildern gelegt.",
          ],
        },
        {
          question: "Braucht auch jeder einen eigenen User?",
          answer: [
            "Jeder leamos User braucht eine E-Mail Adresse für seinen User. leamos kann sich nur so den individuellen Bedürfnissen des einzelnen Klienten anpassen. ",
          ],
        },
        {
          question: "Wie unterstützt leamos die PädagogInnen/Therapeuten?",
          answer: [
            "leamos trainiert eine Reihe von Lesefertigkeiten. Es sind viele Ansätze der Lesetheorie und Erfahrungen aus der pädagogischen Praxis in die Algorithmen des Programms eingeflossen.	 Durch den automatischen Ablauf ersparen sich PädagogInnen und TherapeutInnen viel Zeit bei der Zusammenstellung der Übungen aus den 1780 Listen mit den unzähligen Trainingsvariationen.",
          ],
        },
        {
          question: "Ist leamos ein Spiel?",
          answer: [
            "leamos ist KEIN Spiel - leamos ist ein intensives Lesetraining. leamos strengt an und sollte daher jeden Tag, aber dosiert zum Einsatz kommen. ",
          ],
        },
        {
          question: "Warum ist alles so farblos?",
          answer: [
            "leamos unterscheidet sich sehr von anderen Lernspielen, denn es verwendet keine Farben und keine Figuren.  leamos möchte nicht ablenken und aufmerksamkeitsschwache Kinder in keinster Weise ablenken. ",
            "Klienten haben viele unterschiedliche Spiele und Software ständig in Verwendung, die das visuelle System ohnedies extrem fordern. Alle diese Dinge können ja als eine vereinbarte  Belohnung verwendet werden.",
            "leamos trainieren – ohne Ablenkung und danach ein vereinbarte Belohnung in Form von x-beliebigen Software verwenden – das ist das Konzept.",
          ],
        },
        {
          question:
            "Welche Parameter bestimmen die Schwierigkeit einer Leseeinheit?",
          answer: [
            "Jede leamos Trainingseinheit wird von mehreren Parametern in ihrer Schwierigkeit determiniert:",
            "  - Trainingsart",
            "  - Sequenzlänge",
            "  - Zeichenart",
            "  - Anzeigelayout",
            "  - Antwortlayout",
          ],
        },
        {
          question: "Wie lange ist eine Leseeinheit?",
          answer: [
            "Jede leamos Leseeinheit besteht aus 8 Leseeinheiten. Dies ist sehr wenig, damit sollte die Konzentration bei jedem Training bleiben können. Täglich sind 15 Leseeinheiten im Einzeltraining und 8 Trainingseinheiten im Betreuten Lesen empfohlen.",
          ],
        },
        {
          question:
            "Warum verwendet leamos neben natürlichen Worten auch sinnlose Pseudoworte und Symbole?",
          answer: [
            "Pseudoworte trainieren besonders das synthetische Lesen, den indirekten Leseweg. Zunächst erfolgt eine Abspeicherung häufiger Buchstaben – bzw. Silbenkombinationen, Dann folgt eine intensive Konfrontation mit isolierten Buchstabenabfolgen. Es fehlt allerdings die Anforderung, den Wortsinn im Gehirn abzurufen. ",
            "Die natürlichen oder sinnvollen Worte trainieren verstärkt den direkten Leseweg, die Abspeicherung und das Abrufen von Sichtwörtern im Gedächtnis.  Zunächst wird mit sehr kurzen Worten trainiert, die dann immer länger werden.",
            "Symbole werden im Gehirn in den zu den Buchstaben benachbarten Gebieten verarbeitet ohne jedoch negative Assoziationen und Blockaden wie die Buchstaben hervorzurufen.  Symbole eigenen sich sehr gut, um das visuelle Gedächtnis zu trainieren."
          ],
        },
        {
          question: "Welche Trainingsarten gibt es?",
          answer: [
            "leamos unterscheidet das Einzeltraining und da Betreute Lesen.",
            "Das Betreute Lesen ist Lautes Lesen mit einem Lesepartner.",
            "Das Einzeltraining beinhaltet verschiedene Trainingsarten, die leise und für sich durchgeführt werden. ",
          ],
        },
        {
          question: "Wie kann die Grundeinstufung vorgenommen werden?",
          answer: [
            "Drei Grundeinstufungen sind möglich. Die Namen für die Grundstufen lassen keine Rückschlüsse auf das Leistungsniveau zu. Das ist bewusst so gewählt. ",
            "Student ",
            "Ist ein Leser, bei dem noch nicht alle Buchstaben gefestigt sind. Das Zusammenlautieren ist noch nicht automatisiert.",
            "Einstieg bei der Schwierigkeitslevel 0. Die Anzeigezeit startet beim Betreuten Lesen bei Pseudoworten mit Z1 = 4 sec. und bei Worten mit Z2 = 3 sec. Die anderen Zeiten werden analog der Liste berechnet.",
            "Praktikant ",
            "Kennt alle Buchstaben und das Zusammenlautieren gelingt schon sicher. Der Praktikant arbeitet an der Lesegenauigkeit. Das Erkennen von Buchstabenkombinationen auf einen Blick ist Teil des Trainings.",
            "Einstieg bei Schwierigkeitslevel 300. Die Anzeigezeit startet beim Betreuten Lesen bei Pseudoworten mit Z1 = 2 sec. und bei Worten mit Z2 = 1 sec. Die anderen Zeiten werden analog der Liste berechnet.",
            "Trainee ",
            "Der Trainee ist dabei, sich zu einem geübten Leser zu entwickeln. Schwierige Konsonantencluster bzw. Buchstabenkombinationen oder längere Worte benötigen aber noch Training.  Der Trainee möchte seine Lesegeschwindigkeit steigern. Ziel sind 5 Buchstaben-Pseudoworte in 250ms.  Ein weiteres Augenmerk wird auf das Abspeichern und Abrufen von Wortbildern gelegt. Die 100 häufigsten Fehlerworte und die 500 wichtigsten Worte des Deutschen werden auch zum Tippen angezeigt.",
          ],
        },
        {
          question: "Können die Schulkinder ihre Trainingsergebnisse direkt vergleichen?",
          answer: [
              "Nein, können Sie nicht. Denn selbst bei gleicher Einstufung, gleichem Trainingsstart hat jeder einen anderen Trainingspool und der Zufallsgenerator wählt die Reihenfolge anders aus. Daher können Kinder nebeneinander trainieren und haben keine Vergleichbarkeit der einzelnen Trainingseinheiten.",
              "Damit kann die Bezugsperson jedem Kind die ihm gebührende positive Motivation zukommen lassen.",
              "Vergleichbar sind einzig und allein die Streaks, Lesepunkte und die Diamanten (siehe Belohnungen)."
          ],
        },
        {
          question: "Wie wurde die Reihenfolge der Trainingseinheiten zusammengestellt?",
          answer: [
              "Die Reihenfolge der  leamos Trainingseinheiten ergibt sich aus der Berechnung der Schwierigkeit mit Hilfe Grundlage dazu sind mehrdimensionale Matrizen in Relation der Gewichtung der unterschiedlichen Parameter.",
              "Mit Hilfe der langjährigen Erfahrungen und dem  Wissen von PädagogInnenen und LeseexpertInnen wurden in LEAMOS intelligente Algorithmen eingebaut, die die verschiedenen Parameter so kombinieren, dass die Niveausteigerung sehr behutsam vor sich geht und sich die Trainingspools an das individuelle Bedürfnis des Übenden anpassen. Durch eine abwechslungsreiche Durchmischung der verschiedenen Trainingseinheiten erhöht die Motivation.",
              "Dazu nur ein paar Zahlen: 6000 natürliche Worte und 4000 Pseudoworte finden in leamos Verwendung. Es gibt 1780 Trainingseinheiten, die alle unterschiedlichen Wortstrukturen des Deutschen berücksichtigen."
          ],
        },
        {
          question: "Was ist der Trainingspool?",
          answer: [
              "Im Einzeltraining kommen 20 Trainingslisten in den Trainingspool. Aus diesen werden per Zufallsgenerator die Trainings zur Anzeige ausgewählt. Wird ein Training mit 100% oder dreimal mit mind. 75% erfolgreich abgeschlossen, so fällt die Datei aus dem Trainingspool und eine neue Trainingseinheit wird hereingeholt. ",
              "Beim Betreuten Lesen kommen jeweils 10 Trainingseinheiten in den Trainingspool."
          ],
        },
        {
          question: "Wie lange kann ich mit LEAMOS trainieren?",
          answer: [
              "Trainiert man sechs Mal pro Woche 23 Trainingseinheiten (Tagespensum an Betreutem Lesen und Einzeltraining) und schließt ALLES mit sofortigem Erfolg (über 75% richtig) ab, so kann man 15 Monate trainieren, ohne EINE EINZIGE Trainingseinheit zu wiederholen.",
              "Da man aber meistens ja öfters trainieren muss, bis man den entsprechenden Erfolg erreicht, ist mit einer Trainingszeit von bis zu 36 Monate (oder auch länger) zu rechnen. Die 1780 Trainingslisten mit den 10.000 Wörtern und den zusätzlichen Symbolen, haben genügend Inhalte, um das Training abwechslungsreich zu gestalten.",
              "Student: 15 Monate",
              "Praktikant: 12 Monate",
              "Trainee: 6 Monate"
          ],
        },
        {
          question: "Was, wenn ich mit dem Ergebnis einer Trainingseinheit nicht zufrieden bin?",
          answer: [
              "Dann kann die Trainingseinheit mit der „Nochmal“ Taste einmal wiederholt werden."
          ],
        },
        {
          question: "Welche Arten von Belohnungen gibt es?  ",
          answer: [
              "leamos möchte sowohl den Trainingseifer als auch den Trainingserfolg hervorheben.",
              "Dazu hat leamos  3 Arten von Bonussystemen:",
              "STREAK",
              "Ziel: Motivation zum regelmäßigen Training",
              "Einen Streak bekommt man, wenn an aufeinanderfolgenden Tagen das Tagespensum absolviert wurde.  Da es aber einen oder zwei Tage Pause pro Woche geben sollte, erhält man jede Woche 2 Herzen. Jedes Herz erhält einem den erreichten Streak für einen Tag. Man kann die Herzen auch sammeln. Bis zu sieben Herzen kann man ansammeln, um sie dann während eines Urlaubs etc. einzulösen.",
              "LESEPUNKTE",
              "Ziel: Motivation sich beim Training anzustrengen.",
              "Einerseits bekommt man für die Durchführung Grundpunkte. Somit steigt die Punktezahl in jedem Fall, um sehr leseschwache Kinder nicht zu demotivieren.  Aber es gibt zusätzliche Punkte für die Richtigkeit der Durchführung und die Kürze der Aufblinkzeit.",
              "DIAMANTEN",
              "Ziel: Motivation sich beim Training anzustrengen.",
              "Wurde eine Datei mit 100% Erfolg abgeschlossen, so verschwindet sie sofort aus dem Trainingspool und man erhält 2 Diamanten. Wurde eine Trainingseinheit dreimal mit einem Erfolg von über 75% abgeschlossen, so fällt sie auch aus dem Trainingspool und man erhält 2 Diamanten.",
              "Anhand der gesammelten Streaks, Lesepunkte und Diamanten kann im jeweiligen Setting eine individuelle Belohnung vereinbart werden: Dies könnten Hausübungsgutscheine im Schulsetting sein, während im Privatsetting vielleicht „Zeitgeschenke“ für gemeinsame Aktivitäten im Vordergrund stehen."
          ],
        },
        {
          question: "Was bedeuten das Feuer, die Medaille und der Kristall?",
          answer: [
              "Feuer (Streak)",
              "Um das regelmäßige Üben zu fördern, gibt es das Feuer (Streak). Für jeden Tag, an dem man das Tages ziel im Einzeltraining oder Betreutem Lesen erreicht, erhält man seinen Streak. ",
              "Wöchentlich gibt es 2 Herzen – sie dienen als Gutscheine für 2 übungsfreie Tage, an denen der Streak erhalten bleibt. Dadurch kann man jede Woche einen übungsfreien Tag einhalten, man hat auch noch einen zweiten übungsfreien Tag möglich. Oder man sammelt die Herzen. Bis zu 7 Herzen können angesammelt werden.   So geht sich auch eine übungsfreie Woche aus!",
              "Kristall",
              "Kristall: Wird eine Trainingseinheit dreimal mit einem Erfolg von mindestens 75% absolviert, so gilt die Trainingseinheit als positiv erledigt und fällt aus dem Trainingspool. ",
              "Für jede abgeschlossene Durchführung einer Trainingseinheit gibt es einen „KRISTALL“.  Die in den letzten 7 Tagen erreichten Kristalle werden angezeigt. ",
              "Lesepunkte",
              "Die Lesepunkte werden kontinuierlich gezählt und errechnen sich aus den  durchgeführten Trainings:",
              "-  	2 Punkte für jedes erledigte Training",
              "-  	bis zu 5 Punkte für die Richtigkeit des Spiels (%-Satz der richtigen Antworten zwischen 0 und 5 Punkten)",
              "-  	bis zu 3 Punkte für eine kurze Anzeigezeit (%-Satz für eine Antwortzeit zwischen 2500 ms und 200 ms)",
              "Die Punkte sind gleich egal mit welcher Schwierigkeit trainiert wird!",
              "Damit erhält jeder, der trainiert mindestens 2 Punkte, auch wenn der Erfolg nicht da ist. Aber Eltern können auch erkennen, dass mit hohem Erfolg trainiert wird.",
              "Bei Zeitgeschenken darf sich das Kind beispielsweise aussuchen:",
              "- Spiel ganz frei aussuchen",
              "- Nachmittagsaktivität ganz frei aussuchen",
              "- Buch zum Vorlesen frei aussuchen",
              "Wichtig ist hierbei, dass es immer um die Anwesenheit einer Bezugsperson geht!"
          ],
        },
      ],
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.content {
  width: 70vw;
  margin: auto;
  .faq {
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 3px;
    .question-header {
      padding: 0.75rem 1.25rem;
      // margin-bottom: 0;
      background-color: rgba(0, 0, 0, 0.03);
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);
      text-align: left;
      border-radius: 3px 3px 0 0;
    }
    .answer {
      // padding: 1.25rem;
      p {
        margin: 1.25rem;
        font-weight: 300;
        font-size: 0.9rem;
      }
      text-align: justify;
      max-height: 0;
      transition: max-height 0.5s ease-out;
      overflow: hidden;
    }
  }
}
</style>
