<template>
  <div class="demoWrapper">
      <div class="content">
          <div class="demoWrapper" id="demoWrapper01">             
              <div class="hide">
                  <img src="../assets/images/icons/gps_fixed-24px.svg" alt="">
              </div>
              <div class="show" id="show0">
                  <span style="line-height: 24px;">{{demos[0].currShow}}
                      <img v-bind:key="icon" v-for="icon in demos[0].values[demos[0].rep]" v-bind:src="icon" alt="">
                  </span>
              </div>
              <div class="answer">
                  <!-- <button v-for="option in demos[0].options">Test</button> -->
                  <button>Test</button>
              </div>
              <div class="menu">
                  <button v-on:click="generateDemo01()">Start</button>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'Demo',
  data() {
    return {
        icons:[
            '../assets/images/icons/bug_report-24px.svg',
            '../assets/images/icons/coronavirus-24px.svg',
            '../assets/images/icons/masks-24px.svg',
            '../assets/images/icons/pet-24px.svg'
        ],
        demos: [
            {
                values: [],
        rep: 0,
        currShow: null,
        options: [],
            }
        ],
        display_time: 300,
        demo01:[
            {
                value: 'Katze',
                options: ['Katze', 'Matze', 'Fratze', 'Latz']
            },
        ]
    };
  },
  methods: {
      delay: function(ms) { return new Promise(resolve => setTimeout(resolve, ms)); },
      generateDemo01: function(){
           for(let i = 0; i < 5; i++){
                let iconCopy = this.icons.slice()
                let selection = []
                for(let y = 0; y < 4; y++){
                    let rand = Math.floor(Math.random()*iconCopy.length)
                    console.log(Math.floor(Math.random()*iconCopy.length), iconCopy.length)
                    selection.push(iconCopy[rand])
                    iconCopy.splice(rand,1)
                }
                this.demos[0].values.push(selection)
           }
           
           for(let i = 0; i < 5; i++){
               this.demos[0].options.push([])
               let alarm = 0
                do {
                    let iconCopy = this.icons.slice()
                    let selection = []
                    for(let y = 0; y < 4; y++){
                        let rand = Math.floor(Math.random()*iconCopy.length)
                        selection.push(iconCopy[rand])
                    iconCopy.splice(rand,1)
                    }
                    if(!JSON.stringify(this.demos[0].options[i]).includes(JSON.stringify(selection))){
                        this.demos[0].options[i].push(selection)
                    }
                    alarm++
                } while(this.demos[0].options[i].length<4 && alarm<100);
            }   
            console.log(this.demos[0].options)   
        }   
      },
    //   showRep: function(demo){
          
    //   }

  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    .demoWrapper{
        .content{
            width: 70vw;
            margin: auto;
            display: flex;
          
                .demoWrapper{
                    width: 300px;
                    height: 400px;
                    background-color: aliceblue;
                    position: relative;
                    .hide{
                        position: absolute;
                        top: 0;
                        left: 0;
                        padding-top: 100px;
                        padding-bottom: 15px;
                        background-color: white;
                        width: 100%;
                    }
                    .show{
                        position: absolute;
                        top: 0;
                        left: 0;
                        padding-top: 100px;
                        background-color: white;
                        width: 100%;
                        display: flex;
                        // display: none;
                        img{
                            height: 24px;
                            width: 24px;
                        }
                        .active{
                            display: block;
                        }
                    }
                    .answer{
                        position: absolute;
                        top: 139px;
                        width: 100%;
                        button{
                            background-color: white;
                            border-radius: 5px;
                            padding: 5px 10px;
                            border: none;
                            box-shadow: 1px 1px 3px lightgray;
                            margin: 5px;
                        }
                    }
                    .menu{
                        position: absolute;
                    }
                }
            
        }
    }
</style>
