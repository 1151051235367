<template>
  <div>
  
    <h1>Auswahl Startpunkt</h1>
    <h2>leamos bietet je nach Lesefähigkeit 3 Möglichkeiten ins Programm einzusteigen: </h2>
    <h3>Student </h3>
    <p>    
        Ist ein Leseanfänger, der einige Buchstaben schon kennt. Aber noch nicht alle Buchstaben sind gefestigt. Das Zusammenschleifen von Buchstaben gelingt schon, aber es ist noch nicht automatisiert. 
    </p>
    <h3>Praktikant </h3>
    <p>
      Kennt alle Buchstaben, das Zusammenlautieren gelingt schon sicher und kurze Worte können schon sicher gelesen werden.  Der Praktikant arbeitet an der Lesegenauigkeit. Das Erkennen von Buchstabenkombinationen auf einen Blick und das Automatisieren von Worten, die länger als 4 Buchstaben sind, ist Teil des Trainings. 
    </p>
    <h3>
        Trainee 
    </h3>
    <p>
    Der Trainee ist dabei sich zu einem geübten Leser zu entwickeln. Schwierige Worte und  längere Worte werden zu langsam gelesen. Der Trainee möchte seine Lesegeschwindigkeit steigern. Ein weiteres Augenmerk wird auf das Abspeichern und Schreiben von häufigen deutschen Worten gelegt (Wortbildspeicherung).

    </p>

  </div>
</template>

<script>
export default {
  name: 'Start',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
 div{
        width: 70vw;
        margin: auto;
        text-align: left;
    }
</style>
