<template>
  <div>
       <div class="landingWrapper">
    <div class="content">
      <div class="image">
        <!-- <img class="girl" src="../assets/images/girl.png" alt=""> -->
        <img class="boy" src="../assets/images/girl2.png" alt="" />
        
      </div>
      <div class="text">
        <h1>leamos - Kann mehr als man denkt</h1>
        <h4>Gegenteile ziehen sich an. leamos versucht in vielen Bereichen durch Ausgewogenheit der Trainingsziele den optimalen Trainingserfolg zu erzielen.</h4>
      </div>
    </div>
    <div class="bottomBg">
      <img src="../assets/images/designs/up-concave.svg" alt="" />
    </div>
  </div>
 <div>
          <div class="stripe">
          <img style="width: 250px;" src="../assets/images/arrows/arr_letters.svg" alt="">
          <p >Manchmal gelingt die Konzentration auf das Lesen aufgrund einer bestehenden
Buchstabenabneigung nicht. leamos verwendet aus diesem Grund neben Worten auch
Symbole. Mit Symbolen kann die visuelle Merkfähigkeit sehr gut trainiert werden.</p>
      </div>
      <div class="stripe">
          <img style="width: 250px;" src="../assets/images/arrows/arr_words.svg" alt="">
          <p>Die beiden Arten der neuronalen Leseverarbeitung, der direkter und der indirekte  Leseweg  werden abwechselnd mit Pseudoworten und sinnvollen Worten verbessert. </p>
      </div>
      <div class="stripe">
          <img style="width: 250px;" src="../assets/images/arrows/arr_motivation.svg" alt="">
          <p>Motivation durch schnell wechselnde und abwechslungsreiche Trainingsaufgaben und kurze Trainingssequenzen stehen nicht im Widerspruch zu intensivem Training.</p>
      </div>
      <div class="stripe">
          <img style="width: 250px;" src="../assets/images/arrows/arr_loud.svg" alt="">
          <p>Lautes Lesen kann gut vom  Lesepartner kontrolliert werden, verursacht aber einen höheren Stresslevel. Im Einzeltraining müssen viele unterschiedliche Trainingsarten leise lesend bewältigt werden.  </p>
      </div>
       <div class="stripe">
          <img style="width: 250px;" src="../assets/images/arrows/arr_difficulty.svg" alt="">
          <p>Algorithmen, die die Reihenfolge der Trainingslisten aufgrund von vielen Schwierigkeitsparametern berechnen, ersparen Benutzern den Arbeitsaufwand einer Trainingszusammenstellung.  </p>
                    <p style="margin-top: 15px; font-style: italic;">Diese Algorithmen berücksichtigen  - 3 Itemtypen (Worte, Pseudoworte, Symbole) – 4 Anzeigevarianten -  9 Trainingsarten - 150  Abstufungen der Komplexität der Trainingslisten -1783 Trainingslisten – individuelle Anzeigezeit – 4000 Worte – 6000 Pseudoworte </p>

      </div>
       <div class="stripe">
          <img style="width: 250px;" src="../assets/images/arrows/arr_success.svg" alt="">
          <p>leamos ist es sehr wichtig, auf die Anerkennung des Fleißes der Leseschwachen zu achten, während aber Verbesserungen der Lesefertigkeiten im Belohnungssystem nicht unerwähnt bleiben sollen. </p>
      </div>
        <div class="stripe">
          <img style="width: 250px;" src="../assets/images/arrows/arr_repetition.svg" alt="">
          <p>Der Umbau des Gehirns im Rahmen des Lesenlernens wird durch unendlich viele Wiederholungen bei gleichzeitig ständiger Abwechslung besser erreicht.</p>
      </div>
 </div>
      
  </div>
</template>

<script>
export default {
  name: 'Besonderheiten',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/styles/variables";
.landingWrapper {
  position: relative;
  width: 100vw;
  height: 300px;
  background-color: #f5f5f5;
  .content {
    display: flex;
    width: 70vw;
    margin: auto;
    position: relative;
    @media screen and (max-width: $start-mobile) {
      width: 90vw;
    }
    .image {
      margin-top: -40px;
      height: 300px;
      position: relative;
      img {
        height: 100%;
      }
      @media screen and (max-width: 550px) {
        margin-left: -45px;
      }
      @media screen and (max-width: 450px) {
        margin-left: -65px;
      }
    }
    .text {
      text-align: right;
      width: calc(100% - 270px);
      position: absolute;
      right: 0;
      top: 0;
      // .text-content{

      // }
       @media screen and (max-width: 630px) {
        margin-top: -35px;
      }
      @media screen and (max-width: 550px) {
         width: calc(100% - 270px + 105px);
      }
       @media screen and (max-width: 450px) {
         h1{
           font-size: 1.5rem;
         }
         width: calc(100% - 270px + 115px);
      }
    }
  }
  .bottomBg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 120px;
    img {
      width: 100%;
      height: 120px;
    }
  }

}
    .stripe{
        padding: 20px;
        background-color: $leamos-green-light;
        &:nth-of-type(2n){
            background-color: transparent;
        }
        p{
          max-width: 700px;
          margin: auto;
        }
    }
</style>
