<template>
  <div>
      <h1>Allgemeine Geschäftsbedingungen</h1>
      <h3>1. Rechtliche Informationen</h3>
      <p>
          Der Eigentümerin und Betreiberin dieser Website mit all ihren Inhalten und Angeboten ist Mag. Daniela Arnold (im Folgenden "leamos“).
      </p>
      <p>
          Der Benutzer kann sich mit leamos per E-Mail support@leamos.at oder telefonisch  +43 664 33 979 40 in Verbindung setzen.
      </p>
      <h3>Akzeptanz und Zugang zur Webseite</h3>
      <p>
          Jede Person, die auf diese Website zugreift und/oder eine Handlung im Zusammenhang mit dem Zugriff auf diese Website vornimmt, wie z.B., ohne Einschränkung, diese Website zu besuchen oder zu durchsuchen, ihren Inhalt herunterzuladen, mit der Website zu interagieren, Daten einzugeben oder ähnliches, akzeptiert die in diesem Dokument ausdrücklich dargelegten Bedingungen (nachfolgend die "Bedingungen").
      </p>
      <p>
        Der Zugang und die Nutzung der Website setzt die ausdrückliche Annahme und Kenntnisnahme der rechtlichen Warnhinweise, der Allgemeinen Geschäftsbedingungen, der Cookie-Richtlinie und der Datenschutzrichtlinie voraus. Der Benutzer erklärt und anerkennt:
      </p>
      <ol>
          <li>Dass er/sie die Nutzungsbedingungen, die in den vorliegenden Allgemeinen Geschäftsbedingungen, der Cookie-Richtlinie und der Datenschutzrichtlinie enthalten sind, gelesen und vollständig verstanden hat.</li>
          <li>Dass er/sie eine Person mit ausreichender Vertragsfähigkeit ist. Diese Website richtet sich an natürliche Personen im Alter von über 18 Jahren. Der Benutzer ist für die Wahrhaftigkeit und Wahrhaftigkeit aller angegebenen Daten verantwortlich.</li>
          <li>Dass er/sie alle hier dargelegten Verpflichtungen übernimmt.</li>
      </ol>
      <p>
          Wenn Sie mit diesen Allgemeinen Geschäftsbedingungen oder einem Teil davon nicht einverstanden sind, können Sie von der Nutzung der Website und/oder der angebotenen Software absehen.

      </p>
      <p>
          Diese Allgemeinen Geschäftsbedingungen gelten automatisch für alle Nutzer jeglicher Angebote, die von dieser Website oder ihrem Eigentümer und dem darin enthaltenen oder dadurch bereitgestellten Material (einschließlich Design, Layout, Aussehen, Erscheinungsbild, Informationen, Texte, Grafiken, Bilder, Marken, Logos, Produktnamen und Software; zusammen nachfolgend als "Inhalt" bezeichnet und diese Website und der Inhalt nachfolgend als "Website" bezeichnet). Wenn der Benutzer mit diesen Bedingungen und Konditionen nicht einverstanden ist, muss er den weiteren Zugriff auf die Website und deren Nutzung unterlassen. 
      </p>
      <p>
          Die Version, die zum Zeitpunkt der Nutzung veröffentlicht wird, ist die geltende Version und gilt für alle Angebote oder Vereinbarungen, die sich auf die Dienstleistungen von leamos oder deren Organisation beziehen.  leamos behält sich das Recht vor, ohne vorherige Ankündigung die Präsentation, den Inhalt, die Funktionalität und die Konfiguration der Dienstleistungen und der allgemeinen Website sowie diese Allgemeinen Geschäftsbedingungen ganz oder teilweise zu ändern oder zu aktualisieren; den Zugang und die Nutzung zu beenden oder einzuschränken; Gebühren zu erheben und diese gegebenenfalls nachträglich zu erhöhen. Die geänderten Bedingungen gelten ab dem Zeitpunkt ihrer Veröffentlichung auf dieser Website, ohne dass eine vorherige Ankündigung erforderlich ist. 
      </p>
      <p>
          Ebenso behält sich das Unternehmen das Recht vor, den Zugang und/oder die Nutzung der Website durch den Benutzer zu überwachen sowie technische Maßnahmen zu ergreifen, um zu kontrollieren und zu überprüfen, ob die Website in Übereinstimmung mit den vorliegenden Allgemeinen Geschäftsbedingungen und in voller Übereinstimmung mit der geltenden Gesetzgebung genutzt wird.
      </p>
      <p>
          leamos empfiehlt allen Benutzern, die rechtlichen Nutzungsbedingungen sorgfältig zu lesen und von Zeit zu Zeit zu überprüfen, um etwaige Änderungen zur Kenntnis zu nehmen, da diese für sie verbindlich sind.
      </p>
      <h3>
          2. Angebotene Dienstleistungen und Inhalte
      </h3>
      <p>
          leamos möchte im Allgemeinen die folgenden Dienstleistungen (hier die "Dienstleistungen") über seine Website anbieten:
      </p>
      <ul>
          <li>Den Benutzern die Möglichkeit geben, sich über die von leamos ausgeübte wirtschaftliche Tätigkeit zu informieren.</li>
          <li>Den Benutzern die Möglichkeit zu geben, mit leamos in Kontakt zu treten.</li>
            <li>Die kostenpflichtige Verwendung der leamos Trainingssoftware</li>
      </ul>
      <p>
          Alle Inhalte und Informationen, die von leamos über die Website im Zusammenhang mit den Dienstleistungen angeboten werden, werden für die Zwecke bereitgestellt, die vernünftigerweise erwartet werden können. Das Unternehmen garantiert jedoch nicht und ist nicht haftbar für die Rechtmäßigkeit, Zuverlässigkeit, Nützlichkeit, Wahrhaftigkeit, Genauigkeit, Vollständigkeit oder Aktualität der Informationen, die auf der Website erscheinen. Aus diesem Grund behält sich leamos das Recht vor, den Inhalt, die Dienstleistungen, die Kampagnen und generell alle Teile des Designs und der Konfiguration der Website jederzeit und ohne vorherige Ankündigung zu aktualisieren, zu ändern oder zu entfernen.

      </p>
      <h3>3. leamos Trainingssoftware</h3>
      <p>
        Die leamos Trainingssoftware ist eine Webapplikation die darauf ausgelegt ist [das Lesen zu trainieren]. Die Webapplikation braucht keine Installation, aber benötigt eine durchgehendende Internetverbindung, ein internetfähiges Gerät mit einem Browser und kann unter app.leamos.at aufgerufen werden (im Folgendem „leamos Trainingssoftware“). Eine schlechte Internetverbindung, eine veraltete Browserversion oder ein nicht kompatibles Gerät kann zu Einbußen in der Benutzbarkeit führen die nicht auf leamos zurückzuführen sind.  
      </p>
      <p>
Die Benutzung der leamos Trainingssoftware ist kostenpflichtig. Abos können auf monatlicher Basis und auf jährlicher Basis abgeschlossen werden. Die geltenden Preise sind jeweils die Preise ausgeschildert im letzten Schritt des Zahlungsvorganges. Die Vertragslaufzeit verlängert sich automatisch und der fällige Betrag wird automatisch abgebucht. Der Benutzer kann bis eine Woche vor dem Ablauf der jeweiligen Laufzeit jederzeit per Ende der aktuellen Laufzeit den Vertrag kündigen. Entweder online oder unter support@leamost.at  
      </p>
      <p>
leamos behält sich das Recht vor jedes Abo ohne Angabe von Gründen jederzeit zu kündigen. In Fällen ohne Verschulden des Benutzers wird der bezahlte Betrag pro-rata zurückerstattet.
      </p>
      <p>
          Bei Beendigung eines Abos wird der Nutzer allenfalls stillgelegt und unter Umständen auch unwiderruflich gelöscht. Der Benutzer kann auch eine unwiderrufliche Löschung eines Nutzers und allen Daten beantragen.
      </p>
    <h3>
4. Widerrufsrecht 
    </h3>
    <p>        Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen. Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des ersten Abschlusses des Abos. Um Ihr Widerrufsrecht auszuüben, müssen Sie uns leamos mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter Brief oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. 
</p>
    <p>
        Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.
    </p>
    <p>Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.
</p>
<h3>5. Allgemeine Garantien, Beschränkungen und Haftung</h3>
<p>
leamos behält sich das Recht vor, den Zugriff auf die Website und/oder die Dienstleistungen jederzeit und ohne vorherige Ankündigung zu unterbrechen, sei es aus technischen Gründen, aus Gründen der Sicherheit, Kontrolle, Wartung, Stromausfall oder aus anderen Gründen.
</p>
<p>Daher garantiert leamos nicht die Zuverlässigkeit, Verfügbarkeit oder Kontinuität der Website und ihres Inhalts, daher erfolgt die Nutzung der Website durch den Benutzer auf eigenes Risiko. Der Benutzer kann keine Erwartungen auf der Grundlage der Informationen oder der Bereitstellung der Dienstleistungen durch diese Website aufbauen und daher kann leamos zu keinem Zeitpunkt zur Verantwortung gezogen werden.  
</p>
<p>Nichts auf dieser Website ist als Zusicherungen oder Garantien gewertet werden, dass das Leseniveau eines einzelnen Benutzers gesteigert wird.
</p>
<p>Darüber hinaus übernimmt leamos keine Haftung, die daraus abgeleitet, aber nicht darauf beschränkt ist:
</p>
<ul>
    <li>Die Nutzung des Materials, der Informationen, der Daten und ähnlichem, die auf der Website zur Verfügung gestellt werden, durch den Benutzer, ob illegal oder legal, in Bezug auf Gesetze, Rechte oder Pflichten von leamos, Dritten oder allgemein.
</li>
<li>
    Jede Funktionsstörung der Website und/oder der angebotenen Dienstleistungen, im Allgemeinen, Fehler oder Probleme, die durch die technischen Elemente der Website verursacht werden.

</li>
<li>Alle auf der Website bereitgestellten Informationen. leamos wird zu jeder Zeit versuchen, den Inhalt zu aktualisieren, um die Genauigkeit zu gewährleisten. leamos schließt jedoch ausdrücklich jegliche Verantwortung für die Genauigkeit, Gültigkeit und Authentizität der bereitgestellten Informationen aus. Dies gilt insbesondere, aber nicht ausschließlich, für die Beschreibung der von leamos angebotenen Dienstleistungen.

</li>
<li>
    Jeglicher Inhalt von Dritten, der über Links auf dieser Website zugänglich ist.

</li>
</ul>
<p>
    Ebenso schließt leamos jegliche Haftung für Schäden jeglicher Art aus, die auf das Vorhandensein von Viren oder anderen schädlichen Elementen in den Inhalten zurückzuführen sind, die eine Veränderung der Computersysteme sowie der darin gespeicherten Dokumente oder Systeme verursachen können. Daher ist leamos nicht verantwortlich für jegliche Haftung, die sich daraus ergibt, einschließlich, aber nicht beschränkt auf:

</p>
<ul>
    <li>Fehler, die durch die Computersysteme verursacht oder durch die Einführung eines Virus provoziert werden, Unterbrechungen, Defekte, Verzögerungen beim Betrieb oder bei der Übertragung der Informationen und im Allgemeinen alle anderen Unannehmlichkeiten und Fehler, die sich der Kontrolle des Unternehmens entziehen.
</li>
<li>
    Fehler, die auf die Verletzung dieser Geschäftsbedingungen und die Nutzung der Website und Dienstleistungen zurückzuführen sind.

</li>
<li>
    Sicherheitsfehler, die aufgrund der Verwendung von nicht aktualisierten Versionen von Browsern oder aufgrund von Folgen, die sich aus einer falschen Verwendung des Browsers ergeben können, entweder aufgrund einer falschen Konfiguration, des Vorhandenseins von Computerviren oder aus anderen Gründen, die außerhalb der Kontrolle von leamos liegen. 

</li>
<li>
    Fehler oder Schäden, die sich aus einer eventuellen Inkompatibilität der heruntergeladenen Dateien mit den Computersystemen des Benutzers ergeben. Im Allgemeinen ist der Benutzer für alle heruntergeladenen Inhalte verantwortlich.
</li>
<li>
    Fehler oder Schäden, die der Website aufgrund einer ineffizienten Nutzung des Dienstes und in bösem Glauben durch den Benutzer entstehen.

</li>
<li>
    Fehlfunktionen oder Störungen der von einem Benutzer angegebenen Kontaktinformationen. 

</li>
<li>
    Fehler oder Schäden, die durch Inhalte verursacht werden, die von Dritten mit oder ohne Zustimmung von leamos zur Verfügung gestellt werden. 

</li>
</ul>
<p>
In jedem Fall ist leamos fest entschlossen, dem Benutzer den problemlosen Zugang zur Website zu ermöglichen und eventuelle Störungen rechtzeitig zu beheben. Ungeachtet des Vorgenannten garantiert das Unternehmen nicht, dass der Zugriff auf die Website, den Inhalt und die Software unterbrechungsfrei und fehlerfrei erfolgen wird.

</p>
<p>
    In Bezug auf die leamos Trainingssoftware garantiert leamos dem Benutzer KEINE der folgenden Punkte und schließt daher auch jegliche Haftung aus, die sich möglicherweise aus den angebotenen Dienstleistungen ergibt: 

</p>
<p>
    leamos garantiert zu keinem Zeitpunkt, dass die Leseleistung, objektive oder subjektive eines Nutzers gesteigert wird.

</p>
<h4>6. Geistiges Eigentum</h4>
<p>
    Der gesamte Inhalt dieser Website, einschließlich, aber nicht beschränkt auf den der Website zugrundeliegenden Code/Programmierung, Logos, Texte, Grafiken und Bilder, etc. sind Eigentum von leamos oder einer dritten Partei, die leamos die Zustimmung zur Nutzung gegeben hat. Der gesamte Inhalt ist durch alle anwendbaren Gesetze über geistiges und gewerbliches Eigentum geschützt. Alle auf der Website angezeigten Marken, Handelsnamen und Logos sind eingetragene und nicht eingetragene Marken von leamos oder Dritten. 

</p>
<p>
    Jede vollständige oder teilweise Reproduktion, Nutzung, Verwertung, Verteilung, Veröffentlichung und Vermarktung des Inhalts erfordert eine vorherige schriftliche Genehmigung von leamos. Jede Verwendung, die nicht zuvor von leamos genehmigt wurde, wird als schwerwiegender Verstoß gegen die geistigen oder gewerblichen Eigentumsrechte des Autors betrachtet.

</p>
<p>
    Unter keinen Umständen darf der Zugriff und die Navigation auf der Website durch den Benutzer eine Rücktrittsübertragung, eine Lizenz oder eine vollständige oder teilweise Abtretung der genannten Rechte durch leamos implizieren.

</p>
<h4>7. Datenschutz, Vertraulichkeit und Cookies</h4>
<p>
    leamos hat in Übereinstimmung mit den anwendbaren Datenschutzbestimmungen eine Datenschutz- und Datensicherheitsrichtlinie eingeführt, die alle von leamos gesammelten Daten während der Nutzung der Website und der Interaktion des Benutzers mit der Website schützt. Der Benutzer ist verpflichtet, die Datenschutzbestimmungen vor der Nutzung der Website zu lesen, zu verstehen und zu akzeptieren. Wenn jedoch wesentliche Änderungen bei der Verarbeitung von persönlichen Daten vorgenommen werden, wird diese Änderung allen Benutzern bekannt gegeben, um erneut ihre ausdrückliche Zustimmung einzuholen.

</p>
<p>
    leamos versichert seinen Benutzern, dass es ausreichende Maßnahmen ergriffen hat, um persönliche Daten zu schützen und die Änderung, den Verlust, die Behandlung oder den unbefugten Zugriff auf diese Daten zu verhindern, wobei jederzeit der Stand der Technik in Übereinstimmung mit den geltenden Gesetzen und Vorschriften berücksichtigt wird.

</p>
<p>
    Allein durch den Zugriff auf unsere Website verfügen wir über keinerlei persönliche Daten, die zur Identifizierung der Person als Benutzer verwendet werden können. Dennoch verwendet leamos "Cookies". Ein Cookie ist eine kleine Textdatei, die eine Website auf dem Computer oder mobilen Gerät des Benutzers speichert, wenn dieser die Website besucht. Es ermöglicht der Website, sich die Aktionen und Präferenzen über einen bestimmten Zeitraum hinweg zu merken. Die Benutzer müssen die Cookie-Richtlinie des Unternehmens lesen und akzeptieren.

</p>
<p>
    In jedem Fall haben alle Benutzer das Recht, jederzeit die Rechte auf Zugang, Berichtigung und Löschung ihrer persönlichen Daten auszuüben, indem sie sich schriftlich an leamos unter der elektronischen Adresse support@leamos.at wenden.

</p>
<h4>8. Links zu Seiten Dritter
</h4>
<p>leamos garantiert nicht und übernimmt keinerlei Verantwortung für die Schäden und Verluste, die Benutzer erleiden, die auf Dienstleistungen oder Inhalte Dritter durch Verbindungen, Links oder Verknüpfungen der verlinkten Seiten zugreifen, oder für die Genauigkeit oder Zuverlässigkeit derselben. Die Funktion der Links besteht ausschließlich darin, den Benutzer über die Existenz anderer Informationsquellen im Internet zu informieren, und sind, sofern nicht ausdrücklich anders angegeben, weder als eine Befürwortung, Einladung, Empfehlung oder Anregung solcher anderen Websites durch das Unternehmen gedacht, noch dürfen sie als solche ausgelegt werden. - Es liegt daher in der Verantwortung des Benutzers, solche Informationen zu überprüfen. Das Unternehmen behält sich das Recht vor, die auf dieser Website erscheinenden Links jederzeit einseitig zu entfernen.
</p>
<p>
    Diese Websites, Dienstleistungen und Inhalte Dritter stehen unter der Kontrolle des Eigentümers/Betreibers dieser Websites, und daher liegt jede Art von potentieller Haftung oder Verantwortung bei ihnen. leamos kann in keiner Weise für irgendetwas in Bezug auf diese Dritten oder deren Inhalt verantwortlich gemacht werden.
</p>
<h4>9. Recht und Gerichtsbarkeit
</h4>
<p>
    Im Falle von Ansprüchen, Streitigkeiten oder Fragen im Zusammenhang mit dieser Website, ihren Diensten und Inhalten unterliegen sie den Gesetzen der Republik Österreich, und beide Parteien unterstellen diese Ansprüche, Streitigkeiten oder Fragen ausdrücklich der ausschließlichen Gerichtsbarkeit der Gerichte von St Pölten.

</p>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
 div{
        width: 70vw;
        margin: auto;
        text-align: left;
    }
</style>
