<template>
  <div>
    <div class="landingWrapper">
      <div class="content">
        <div class="image">
          <!-- <img class="girl" src="../assets/images/girl.png" alt=""> -->
          <img class="boy" src="../assets/images/girl3.png" alt="" />
        </div>
        
        <div class="text"><h1>leamos - wir lesen immer besser, immer schneller</h1>
        <h4>Individuelle Anzeigezeit und Aufgabenzusammenstellung fördern ohne zu überfordern. Belohnt
werden nicht nur Erfolg sondern auch Fleiß.</h4>
        </div>
      </div>
      <div class="bottomBg">
        <img src="../assets/images/designs/up-concave.svg" alt="" />
      </div>
    </div>
   <div class="contentWrapper">
   <div class="content">
       <div class="step one">
         <h3>Haben Kinder/Klienten Probleme mit </h3>
         <ul>
           <li>Blitzlesen von Worten</li>
           <li>genauem Lesen</li>
           <li>guten Lesefluss</li>
           <li>Aufmerksamkeit und Gedächtnis  </li>
           <li>Abspeicherung von Buchstabenkombinationen und Schreibweisen</li>
         </ul>
       </div>
     <div class="step two">
       <h3>So hilft ihnen leamos bei der Erreichung der Ziele von:</h3>
       <ul>
         <li>Lesegenauigkeit</li>
         <li>Lesegeschwindigkeit</li>
         <li>gutes visuelles und auditives Kurzzeitgedächtnis</li>
         <li>bleibende Abspeicherung und Abruf von Wortbildern</li>
       </ul>
     </div>
     <div class="step three">
       <h3>Dabei unterstützt leamos das Lernen und Üben durch</h3>
       <ul>
         <li>kurze Trainingssequenzen</li>
         <li>abwechslungsreiches Training</li>
         <li>riesiges Trainingsangebot</li>
         <li>Bonussystem</li>
         <li>Wenig Zeitaufwand für Eltern und Pädagogen</li>
       </ul>
     </div>
         <h4>leamos  bietet 8 verschiedene Trainings im Einzeltraining (leises Lesen) an. Für jede Trainingsart wird individuell die Anzeigezeit der Worte, Pseudoworte oder Symbole ermittelt und langsam je nach Erfolg gesteigert.</h4>

   </div>
   </div>
   <div class="bottom">
      <img src="../assets/images/designs/down-convex.svg" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/styles/variables";
.landingWrapper {
  position: relative;
  width: 100vw;
  height: 300px;
  background-color: #f5f5f5;
  .content {
    display: flex;
    width: 70vw;
    margin: auto;
    position: relative;
    
    @media screen and (max-width: $start-mobile) {
      width: 90vw;
    }
    .image {
      margin-top: -40px;
      height: 300px;
      position: relative;
      img {
        height: 100%;
      }
      @media screen and (max-width: 550px) {
        margin-left: -45px;
      }
      @media screen and (max-width: 450px) {
        margin-left: -65px;
      }
    }
    .text {
      text-align: right;
      width: calc(100% - 270px);
      position: absolute;
      right: 0;
      top: 0;
      // .text-content{

      // }
       @media screen and (max-width: 630px) {
        margin-top: -35px;
      }
      @media screen and (max-width: 550px) {
         width: calc(100% - 270px + 105px);
      }
       @media screen and (max-width: 450px) {
         h1{
           font-size: 1.5rem;
         }
         width: calc(100% - 270px + 115px);
      }
    }
  }
  .bottomBg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 120px;
    img {
      width: 100%;
      height: 120px;
    }
  }
}
.contentWrapper{
  padding: 20px;
        background-color: $leamos-green-light;
       .content{
         width: 70vw;
         margin: auto;
          .step{
          border-radius: 8px;
          box-shadow: 0 0 10px 3px rgba(211, 211, 211, 0.607);
          width: fit-content;
          // height: 100px;
           @media screen and (max-width: 1000px) {
        width: 100%;
      }
          text-align: left;
          position: relative;
          padding: 15px 20px 30px 20px;
          &.one{
background-color: #f5f5f5;
          }
          &.two{
            margin: auto;
            top: -20px;
            background-color: #f5f5f5;
          }
          &.three{
            margin-left: auto;
            top: -40px;
            background-color: #f5f5f5;
          }
        }
       }
}
 .bottom {
    background-color: #f5f5f5;
    width: 100%;
    height: 80px;
    img {
      width: 100%;
      height: 80px;
    }
  }
</style>
